import { Button, Form, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  FingerNumber,
  aadharPattern,
  captureUrl,
  deviceOptions,
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
  handleSubmitForAeps,
  parseXml,
} from "../../../../../Utils";
import {
  MemberProfile,
  YesBankAepsBiometric,
  YesBankAepsGetWadh,
  getAllAepsYesMasters,
} from "../../../../../ApiServices/Apifun";
import { xml2json } from "../../../../../Utils/xml2json";
import { Images } from "../../../../../Controller";
import axios from "axios";
import CommonInput from "../../../../../Common/CommonInput";
import { useLocation, useNavigate } from "react-router-dom";
import { setProfile, setselectedTab } from "../../../../../Store/B2bslice";
import { useDispatch } from "react-redux";

const YesBankBioMetric = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [showFingerImage, setShowFingerImage] = useState(false);
  const [wadh, setWadh] = useState("");
  const [consent, setConsent] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleAepsMasters = () => {
    getAllAepsYesMasters()
      .then((res) => {
        setDevices(
          res.data.device_type.map((i) => ({
            label: i.type,
            value: i.id,
            key: i.id,
          }))
        );
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    handleAepsMasters();
    YesBankAepsGetWadh()
      .then((res) => {
        if (res.status && res.data.wadh !== null) {
          setWadh(res.data.wadh);
        } else {
          navigate("/banking/aepsyes-onboard");
        }
      })
      .catch((err) => console.log(err));
  }, []);
  var PIDOPTS =
    '<PidOptions ver="1.0">' +
    `<Opts fCount="1" fType="2" iCount="" iType="" pCount="" pType="" format="0" pidVer="2.0" timeout="10000" env="P" otp="" wadh="${wadh}" posh=""/>` +
    "</PidOptions>";
  var MANTRAPIDOPTS = `<PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" wadh="${wadh}" posh="UNKNOWN" env="P" /> </PidOptions>`;
  const mantrairisPIDOPTS = `<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="0" fType="0" iCount="1" pCount="0" pgCount="1" format="0"   pidVer="2.0" timeout="10000" pTimeout="20000" wadh="${wadh}" posh="UNKNOWN" env="P" /> <CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>`;
  const startekPIDOPTS = `<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" wadh="${wadh}" posh="UNKNOWN" env="P" /> </PidOptions>`;

  const capture = (val, port = 11100) => {
    setBtnLoading(true);
    axios({
      method: "CAPTURE", // Set the custom request method here
      url:
        val.device === "morpho" || val.device === 1
          ? captureUrl
          : val.device === "startek" || val.device === 5
          ? "https://localhost:11200/rd/capture"
          : `https://127.0.0.1:${port}/rd/capture`,
      data:
        val.device === "morpho" || val.device === 1
          ? PIDOPTS
          : val.device === "mantra" || val.device === 6
          ? MANTRAPIDOPTS
          : val.device === "startek" || val.device === 5
          ? startekPIDOPTS
          : mantrairisPIDOPTS,
      headers: {
        "Content-Type": "text/xml",
        Accept: "text/xml",
      },
    })
      .then((response) => {
        const parsed = parseXml(response.data);
        const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
        console.log(xmltojson, "wd/jwelhfdweweklwehflwehlfkwe");
        if (xmltojson.PidData.Resp.errCode !== "0") {
          dispatchtoast(
            `Failed to fetch fingerprint because of ${xmltojson.PidData.Resp.errInfo}`,
            true
          );
          setBtnLoading(false);
        } else {
          dispatchtoast("Fingerprint Captured SuccessFully");
          const { rdsVer } = xmltojson.PidData.DeviceInfo;
          const { ci } = xmltojson.PidData.Skey;
          var parser = new DOMParser();
          var xmlz = parser.parseFromString(response.data, "application/xml");
          const payload = {
            consent: consent,
            mac_address: "",
            device_id: navigator.userAgent ?? "",
            latitude: getLocalStorageItem("latitude"),
            longitude: getLocalStorageItem("longitude"),
            aadhaar_number: val.aadhaar_number,
            cert_expiry_date: ci,
            device_data_xml: btoa(new XMLSerializer().serializeToString(xmlz)),
            finger_data: val.finger,
            serial_number: Array.isArray(
              xmltojson.PidData.DeviceInfo.additional_info.Param
            )
              ? xmltojson.PidData.DeviceInfo.additional_info.Param[0].value
              : xmltojson.PidData.DeviceInfo.additional_info.Param.value,
            version_number: rdsVer,
            session_key: xmltojson.PidData.Skey["#text"],
            device_type: val.device,
          };
          const formdata = new FormData();
          Object.entries(payload).forEach(([key, val]) =>
            formdata.append(key, val)
          );
          YesBankAepsBiometric(formdata)
            .then((res) => {
              if (res.status) {
                navigate(
                  location.state?.from
                    ? location.state?.from
                    : "/banking/aeps-cash-withdrawl",
                  { state: { allow: true } }
                );
                MemberProfile()
                  .then((res) => {
                    if (res.status) {
                      dispatch(setProfile(res.data.profile));
                    } else {
                      dispatchtoast("Unable to fetch profile detail", true);
                    }
                  })
                  .catch((err) => console.log(err, "errr"));
                dispatchtoast(res.data.message);
              } else {
                navigate("/banking/aepsyes-onboard");
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => setBtnLoading(false));
          // .finally(() => navigate("/dashboard"));
        }
      })
      .catch((error) => {
        console.log(error);
        setBtnLoading(false);
        dispatchtoast("Something went wrong!", true);
      });
  };
  useEffect(() => {
    dispatch(setselectedTab("17"));
  }, []);
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2 bg-white shadow-normal  rounded-3xl p-5">
          <div className="flex justify-between items-center">
            <p className="text-[15px] font-bold mb-4">Biometric</p>
            <img
              src={Images.yesBank}
              className="flex justify-center  items-center w-32"
              alt="yes bank"
            />
          </div>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) =>
              consent
                ? handleSubmitForAeps(val, capture)
                : dispatchtoast("Please select term and conditions 😊.", true)
            }
          >
            <Form.Item
              name={`finger`}
              label={"Select Finger"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select finger!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a finger"
                className="w-full mt-2"
                options={FingerNumber}
              />
            </Form.Item>
            <Form.Item
              name={`aadhaar_number`}
              label={"Aadhar Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter aadhar number!",
                },
                {
                  pattern: aadharPattern,
                  message: "Please enter valid aadhar!",
                },
              ]}
            >
              <CommonInput placeholder={"Aadhar Number"} />
            </Form.Item>
            <Form.Item
              name={`device`}
              label={"Select Device"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select device!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a device"
                filterOption={filterOption}
                className="w-full mt-2"
                options={devices}
              />
            </Form.Item>
            <div className="flex justify-end w-full items-center">
              <Button
                onClick={() => setShowFingerImage(true)}
                className=" bg-primary border-none mt-4"
                style={{ color: "white" }}
              >
                Hand Image
              </Button>
            </div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Select one device!",
                },
              ]}
              initialValue={"N"}
              name={`consent`}
              labelCol={{ span: 24 }}
            >
              <div className="mt-2 flex justify-start items-center gap-x-3  checkbox">
                <input
                  checked={consent}
                  onChange={(e) => {
                    e.target.checked ? setConsent(true) : setConsent(false);
                  }}
                  className="rounded-md"
                  type="checkbox"
                />
                <p
                  onClick={() => {
                    consent ? setConsent(false) : setConsent(true);
                  }}
                  className="text-textGray cursor-pointer select-none text-xs italic required"
                >
                  I hereby agree and permit the Bank to use my personal
                  information (including sensitive personal data) and to share
                  the same with statutory/regulatory/law enforcement authorities
                  and payment networks, for monitoring and/or reporting
                  purposes.”
                </p>
              </div>
            </Form.Item>{" "}
            <Form.Item>
              <Button
                loading={btnLoading}
                className="w-full bg-primary border-none mt-4"
                style={{ color: "white" }}
                htmlType="submit"
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Modal
        footer={null}
        open={showFingerImage}
        onCancel={() => setShowFingerImage(false)}
      >
        <img src={Images.Finger} alt="finger" className="w-52 h-52 m-auto" />
      </Modal>
    </>
  );
};

export default YesBankBioMetric;
