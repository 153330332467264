import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";
import { getbbps2Services } from "./Bbps2Endpoint";
import Bbps2Recharge from "./Bbps2Recharge";
import BBps2Services from "./BBps2Services";
import BBps2Tata from "./BBps2Tata";

const BBPS2 = () => {
  const [current, setCurrent] = useState(0);
  const [allServices, setAllServices] = useState([]);
  const dispatch = useDispatch();
  const Tabs = [
    // {
    //   key: 1,
    //   name: "Recharge",
    //   component: (
    //     <Bbps2Recharge
    //       services={allServices?.filter((i) => i.operator_type === "1")}
    //     />
    //   ),
    // },
    {
      key: 1,
      name: "BBPS",
      component: (
        <BBps2Services
          services={allServices?.filter((i) => i.operator_type === "2")}
        />
      ),
    },
    // {
    //   key: 3,
    //   name: "Tata Connection",
    //   component: (
    //     <BBps2Tata
    //       services={allServices?.filter((i) => i.operator_type === "3")}
    //     />
    //   ),
    // },
  ];

  const getAllServices = () => {
    getbbps2Services()
      .then((res) => {
        if (res.status) {
          setAllServices(res.data);
        }
      })
      .catch((err) => console.log(res));
  };
  useEffect(() => {
    dispatch(setselectedTab("2011"));
    getAllServices();
  }, []);
  return (
    <>
      <div className="mt-8 hidden bg-white rounded-lg md:grid grid-cols-1  place-items-start md:place-items-center gap-y-4">
        {Tabs.map((i, index) => {
          return (
            <div className="w-full text-center">
              <p
                onClick={() => {
                  setCurrent(index);
                }}
                className={`cursor-pointer transition-all  ${
                  i.key - 1 === current
                    ? "bg-primary tab-active text-white"
                    : "border border-[#EBEBEB]"
                }  py-2 `}
              >
                {i.name}
              </p>
            </div>
          );
        })}
      </div>
      <div className="mt-2  md:hidden grid grid-cols-1  place-items-center md:place-items-center gap-4">
        {Tabs.map((i, index) => {
          return (
            <div className="w-full text-center">
              <p
                onClick={() => {
                  setCurrent(index);
                }}
                className={`w-full relative ${
                  i.key - 1 === current
                    ? "border-b-2 tab-active border-b-primary "
                    : "border-b text-gray-500 border-b-gray-500"
                } cursor-pointer w-full py-2 `}
              >
                {i.name}
              </p>
            </div>
          );
        })}
      </div>
      <div className="mt-4">{Tabs[current].component}</div>
    </>
  );
};

export default BBPS2;
