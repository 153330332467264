import { Get, getAPIUrl, Post } from "../../../ApiServices/apiMethod";

export const bbps2Endpoint = {
  getbbps2Services: "Api/V1/Bbps2/getBbps2Services",
  getOperators: "Api/V1/Bbps2/getOperators",
  bbpsbillPayment: "Api/V1/Bbps2/bbps2BillPayment",
  bbpsFetchPayment: "Api/V1/Bbps2/fetchBill",
  checkbbps2statsu: "Api/V1/Bbps2/checkBbps2Status",
  bbps2Report: "Api/V1/Bbps2/bbps2Report",
  dthConnectionPlans: "Api/V1/Bbps2/getDthConnectionPlans",
  applyTataDthConnection: "Api/V1/Bbps2/appyTataDthConnection",
  dthConnectionReport: "Api/V1/Bbps2/tataDthConnectionReport",
};

export const getbbps2Services = (param = "") => {
  const url = getAPIUrl(bbps2Endpoint.getbbps2Services, param);
  return Get(url);
};
export const getOperators = (data,param = "") => {
  const url = getAPIUrl(bbps2Endpoint.getOperators, param);
  return Post(url, data);
};
export const bbpsbillPayment = (data,param = "") => {
  const url = getAPIUrl(bbps2Endpoint.bbpsbillPayment, param);
  return Post(url, data);
};
export const bbpsFechbillPayment = (data,param = "") => {
  const url = getAPIUrl(bbps2Endpoint.bbpsFetchPayment, param);
  return Post(url, data);
};
export const checkbbps2statsu = (data,param = "") => {
  const url = getAPIUrl(bbps2Endpoint.checkbbps2statsu, param);
  return Post(url, data);
};
export const bbps2Report = (data,param = "") => {
  const url = getAPIUrl(bbps2Endpoint.bbps2Report, param);
  return Post(url, data);
};
export const dthConnectionPlans = (param = "") => {
  const url = getAPIUrl(bbps2Endpoint.dthConnectionPlans, param);
  return Get(url);
};
export const applyTataDthConnection = (data,param = "") => {
  const url = getAPIUrl(bbps2Endpoint.applyTataDthConnection, param);
  return Post(url, data);
};
export const dthConnectionReport = (data,param = "") => {
  const url = getAPIUrl(bbps2Endpoint.dthConnectionReport, param);
  return Post(url, data);
};
