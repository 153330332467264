import { Spin,  Tabs } from "antd";
import React, { useEffect, useState } from "react";
import AllPlans from "./AllPlans";
import { GetPlans } from "../../../../ApiServices/Apifun";

const PlanDetails = ({ operator, circle, getAmount, setAllPlans  }) => {
  const [tabItems, setTabItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getALlPlans = () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("operator", operator);
    formdata.append("circle", circle);

    GetPlans(formdata)
      .then((res) => {
        if (res.status) {
          const items = res.data.plans.map((item, index) => {
            return {
              key: index + 1,
              label: item.group_name,
              children: (
                <AllPlans
                  setAmount={getAmount}
                  group_name={item.group_name}
                  data={item.plans}
                />
              ),
            };
          });
          setTabItems(items);
          setAllPlans(res.data.plans);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (operator && circle) {
      getALlPlans();
    }
  }, [operator, circle]);
  return (
    <>
      {loading && (
        <div className="prepaid col-span-3 flex justify-center items-center font-semibold text-lg w-full h-full bg-white rounded-3xl shadow-normal p-5">
          <Spin
            className={`col-span-2 flex justify-center items-center  ${
              loading && "w-full h-full"
            } `}
            spinning={loading}
          />
        </div>
      )}
      {!loading && tabItems.length ? (
        <>
        <div className="prepaid col-span-2 w-full bg-white rounded-3xl shadow-normal p-5">
        <p className="text-lg font-bold">Browse Plan of {operator} - {circle}</p>
          <Tabs defaultActiveKey="1" items={tabItems} onChange={() => {}} />
        </div>
        </>
      ) : (
        ""
      )}
      {!loading && !tabItems.length && (
        <div className="prepaid col-span-2 flex justify-center items-center font-semibold text-lg w-full h-full bg-white rounded-3xl shadow-normal p-5">
          <p>There is no related plans!</p>
        </div>
      )}
    </>
  );
};

export default PlanDetails;
