import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  MantraPIDOPTS,
  MorphpoPIDOPTS,
  ToastContainerMsg,
  aadharPattern,
  captureUrl,
  convertXmlToJson,
  deviceOptions,
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
  handleSubmitForAeps,
  mantrairisPIDOPTS,
  mobilePattern,
  parseXml,
  startekPIDOPTS,
} from "../../../../../Utils";
import {
  AepsMinistatement,
  AepsYesMinistatement,
  getAllAepsYesMasters,
} from "../../../../../ApiServices/Apifun";
import { xml2json } from "../../../../../Utils/xml2json";
import axios from "axios";
import ReportDrawer from "../../../../../Common/ReportDrawer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setselectedTab } from "../../../../../Store/B2bslice";
import CommonInput from "../../../../../Common/CommonInput";
import { Images } from "../../../../../Controller";

const YesMinistatement = ({setOpenModal,setType}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [userBanks, setUserBanks] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [response, setResponse] = useState([]);
  const[consent,setConsent]=useState(false)
  const profiledetail = useSelector((state) => state.B2B.profileDetail);
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const handleAepsMasters = () => {
      getAllAepsYesMasters()
        .then((res) => {
          setUserBanks(
            res.data.aeps_banks.map((i) => ({
              label: i.bank_name,
              value: i.id,
              key: i.id,
            }))
          );
        })
        .catch((err) => console.log(err));
    };
    handleAepsMasters();
  }, []);

  const capture = (val, port=11100) => {
    setBtnLoading(true);
    axios({
      method: "CAPTURE", // Set the custom request method here
      url:
        val.device === "morpho"
          ? `https://localhost:${port}/capture`
          : val.device === "startek"
          ? "https://localhost:11200/rd/capture"
          : `https://127.0.0.1:${port}/rd/capture`,
      data:
        val.device === "morpho"
          ? MorphpoPIDOPTS
          : val.device === "mantra"
          ? MantraPIDOPTS
          : val.device === "startek"
          ? startekPIDOPTS
          : mantrairisPIDOPTS,
      headers: {
        "Content-Type": "text/xml",
        Accept: "text/xml",
      },
    })
      .then((response) => {
        const parsed = parseXml(response.data);
        const xmltojson = JSON.parse(xml2json(parsed).replace("undefined", ""));
        if (xmltojson.PidData.Resp.errCode !== "0") {
          dispatchtoast(
            `Failed to fetch fingerprint because of ${xmltojson.PidData.Resp.errInfo}`,
            true
          );
          setBtnLoading(false);
        } else {
          dispatchtoast("Fingerprint Captured SuccessFully");
          const payload = {
            consent:consent?"Y":"N",
            mac_address:"",
            device_id: navigator.userAgent??"",
            customer_mobile: val.customer_mobile,
            serial_number: Array.isArray(
              xmltojson.PidData.DeviceInfo.additional_info.Param
            )
              ? xmltojson.PidData.DeviceInfo.additional_info.Param[0].value
              : xmltojson.PidData.DeviceInfo.additional_info.Param.value,
            customer_aadhaar_number: val.customer_aadhaar_number,
            bio_metricdata: response.data.replace(/"/g, "'"),
            user_bank: val.user_bank.value,
            user_bank_name: val.user_bank.label,
            latitude: getLocalStorageItem("latitude"),
            longitude: getLocalStorageItem("longitude"),
          };
          const formdata = new FormData();
          Object.entries(payload).forEach(([key, val]) =>
            formdata.append(key, val)
          );
          AepsYesMinistatement(formdata)
            .then((res) => {
              if (res.status_1 === false) {
                setType("status1")
                setOpenModal(true)
              }
              if (res?.data?.status === "SUCCESS") {
                setResponse(res);
                setDrawerOpen(true);
              } 
            })
            .catch((err) => {
              console.log({ err });
              if (err.response.data.status_1 === false) {
                setType("status1")
                setOpenModal(true)
              }
            })
            .finally(() => setBtnLoading(false));
          // .finally(() => navigate("/dashboard"));
        }
      })
      .catch((error) => {
        console.log({ error });
        setBtnLoading(false);
      });
  };

  const Content = () => {
    return (
      <>
        <div className="bg-white w-full mt-2 rounded-md p-4">
          <div className="font-semibold text-xs">
            <p>Merchant Name : {response?.data?.merchant_name ?? "-"}</p>
            <p>Bank Name : {response?.data?.bank_name ?? "-"}</p>
            <p>Aadhar Number : {response?.data?.masked_aadhaar ?? "-"}</p>
            <p>Transaction I'd: {response?.data.userTransactionId ?? "-"}</p>
            <p>Bank RRN: {response?.data.bankRrn}</p>
          </div>
        </div>
        <div className="bg-white w-full  p-4 rounded-md">
          <p className="font-bold">Statements</p>
          {response?.length !== 0 &&
            response?.data.miniStatementData.split("\n").map((i, index) => {
              return (
                <>
                  <div className="mt-4">
                    <p className="font-semibold text-xs">Data: {i}</p>
                  </div>
                  <hr className="mt-2" />
                </>
              );
            })}
        </div>
      </>
    );
  };
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2 bg-white shadow-normal  rounded-3xl p-5">
          <div className="flex justify-between items-center">
          <p className="font-bold text-[15px] ">Mini Statement</p>
          {/* <div className="flex justify-end gap-2 items-center">
           <img
             src={getLocalStorageItem("detail")?.logo}
              className="flex justify-center  items-center w-32"
              alt="yes bank"
            />
             <img
              src={Images.yesBank}
              className="flex justify-center  items-center w-32"
              alt="yes bank"
            />
           </div> */}
          </div>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) =>consent? handleSubmitForAeps(val, capture):dispatchtoast("Please select term and conditions 😊.",true)}
          >
            <Form.Item
              name={`customer_mobile`}
              label={"Mobile Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Mobile Number!",
                },
                {
                  pattern: mobilePattern,
                  message: "Invalid mobile number format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Mobile Number"
                name="customer_mobile"
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>
            <Form.Item
              name={`customer_aadhaar_number`}
              label={"Aadhar Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter aadhar Number!",
                },
                {
                  pattern: aadharPattern,
                  message: "Invalid aadhar number format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Aadhar Number"
                name="customer_aadhaar_number"
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>{" "}
            <Form.Item
              name={`user_bank`}
              label={"Select Bank"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select one bank!",
                },
              ]}
            >
              <Select
                showSearch
                labelInValue={true}
                placeholder="Select a bank"
                filterOption={filterOption}
                className="w-full mt-2"
                options={userBanks}
              />
            </Form.Item>
            <Form.Item
              name={`device`}
              label={"Select Device"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select one device!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Device"
                filterOption={filterOption}
                className="w-full mt-2"
                options={deviceOptions}
              />
            </Form.Item>
            <Form.Item
              name={`consent`}
              labelCol={{ span: 24 }}
            >
              <div className="mt-2 flex justify-start items-center gap-x-3  checkbox">
              <input
                checked={consent}
                  onChange={(e) => {
                    e.target.checked
                      ? setConsent(true)
                      : setConsent(false);
                  }}
                  className="rounded-md"
                  type="checkbox"
                />
                <p onClick={() => {
                    consent?setConsent(false):setConsent(true)
                  }} className="text-textGray cursor-pointer select-none text-xs italic required">
                  I hereby agree and permit the Bank to use my personal
                  information (including sensitive personal data) and to share
                  the same with statutory/regulatory/law enforcement authorities
                  and payment networks, for monitoring and/or reporting
                  purposes.”
                </p>
              </div>
            </Form.Item>{" "}
            <Form.Item>
              <Button
                loading={btnLoading}
                className="w-full bg-primary border-none mt-4"
                style={{ color: "white" }}
                htmlType="submit"
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {drawerOpen && (
        <ReportDrawer
          open={drawerOpen}
          setOpen={setDrawerOpen}
          content={<Content />}
          foryes={true}
          title="Mini Statement"
        />
      )}
    </>
  );
};

export default YesMinistatement;
