import axios from "axios";
import {
  dispatchtoast,
  getLocalStorageItem,
  removeLocalStorageItem,
  showError,
} from "../../Utils";

class ApiService {
  constructor() {
    this.service = axios.create({
      baseURL: "",
    });

    this.service.interceptors.request.use(this.handleRequest);
    this.service.interceptors.response.use(
      this.handleResponse,
      this.handleError
    );
  }
  setBaseUrl(baseURL) {
    this.service.defaults.baseURL = baseURL;
  }
  handleRequest(config) {
    console.log(config);
    if (!config.hasOwnProperty("baseURL")|| !localStorage.getItem("detail")) {
      axios
        .post(
          `https://${window?.location?.host.split('.').slice(1).join('.')}/auth/getAdminDetail`,
          {
            domain_name: window?.location?.host?.split('.').slice(1).join('.'),
            // domain_name: "reseller.ezulix.co",
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          if (res.data.status) {
            localStorage.setItem("detail", JSON.stringify(res.data.adminData));
          }
        })
        .catch((err) => console.log(err));
    }
    // Add JWT token or other headers if needed
    // Example: config.headers['Authorization'] = `Bearer ${getLocalStorageItem('jwt_token')}`;
    return config;
  }

  handleResponse(response) {
    if (response.data.status) {
      return response.data;
    } else if (response.data.formErrors) {
      showError(response.data.formErrors);
    } else if (response.data.validationError) {
      showError(response.data.validationError);
    } else {
      dispatchtoast(response.data.message ?? response.data.error, true);
    }
    return response.data;
  }

  handleError(error) {
    const { status } = error?.response;
    if (status === 401) {
      dispatchtoast(
        error?.response.data.message ?? error?.response.data.msg,
        true
      );
      removeLocalStorageItem("isLoggedIn");
      removeLocalStorageItem("accessToken");
      window.location.href = "/";
    }
    if (status && status >= 400 && status < 500) {
      if (error.response.data.formErrors) {
        showError(error.response.data.formErrors);
      } else if (error.response.data.validationError) {
        showError(error.response.data.validationError);
      } else {
        dispatchtoast(
          error?.response.data.message ?? error?.response.data.error,
          true
        );
      }
    } else if (status && status >= 500) {
      dispatchtoast(
        error?.response.data.message ?? error?.response.data.msg,
        true
      );
    }
    return Promise.reject(error);
  }
  // Methods for HTTP requests
  get(url, config) {
    return this.service.get(url, config);
  }

  post(url, data, config) {
    return this.service.post(url, data, config);
  }

  put(url, data, config) {
    return this.service.put(url, data, config);
  }

  patch(url, data, config) {
    return this.service.patch(url, data, config);
  }

  delete(url, config) {
    return this.service.delete(url, config);
  }
}
const apiService = new ApiService();
export default apiService;
