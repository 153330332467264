import { getLocalStorageItem } from "../Utils";
import apiService from "./Axios";

export const getHeaders = (requireAuth = true) => {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  if (requireAuth) {
    const accessToken = `Bearer ${getLocalStorageItem("access_token")}`;
    headers.Authorization = `${accessToken}`;
  }
  return headers;
};

export const Get = (url) => {
  apiService.setBaseUrl(
    `https://${window?.location?.host.split(".").slice(1).join(".")}`
  );
  //  apiService.setBaseUrl(
  //   `https://ishwarpay.in/`
  // );

  return apiService.get(url, { headers: getHeaders() });
};

export const Post = (url, data, auth) => {
  apiService.setBaseUrl(
    `https://${window?.location?.host.split(".").slice(1).join(".")}`
  );
  //  apiService.setBaseUrl(
  //   `https://ishwarpay.in/`
  // );
  return apiService.post(url, data, { headers: getHeaders(auth) });
};

export const Patch = (url, data) => {
  apiService.setBaseUrl(
    `https://${window?.location?.host.split(".").slice(1).join(".")}`
  );
  //  apiService.setBaseUrl(
  //   `https://ishwarpay.in/`
  // );
  return apiService.patch(url, data, { headers: getHeaders() });
};

export const Put = (url, data) => {
  apiService.setBaseUrl(
    `https://${window?.location?.host.split(".").slice(1).join(".")}`
  );
  //  apiService.setBaseUrl(
  //   `https://ishwarpay.in/`
  // );
  return apiService.put(url, data, { headers: getHeaders() });
};

export const Delete = (url) => {
  apiService.setBaseUrl(
    `https://${window?.location?.host.split(".").slice(1).join(".")}`
  );
  //  apiService.setBaseUrl(
  //   `https://ishwarpay.in/`
  // );
  return apiService.delete(url, { headers: getHeaders() });
};

export const getAPIUrl = (url, params = "") => {
  return url + `${params}`;
};
export const FormDataPost = (url, data) => {
  apiService.setBaseUrl(
    `https://${window?.location?.host.split(".").slice(1).join(".")}`
  );
  //  apiService.setBaseUrl(
  //   `https://ishwarpay.in/`
  // );
  return apiService.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const FormDataPatch = (url, data) => {
  apiService.setBaseUrl(`https://${window?.location?.host.split(".").slice(1).join(".")}`);
  return apiService.patch(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getErrors = (error) => {
  const errorData = error.response.data.error;
  const errors = {};
  Object.keys(errorData).forEach((key) => {
    errors[key] = errorData[key];
  });
  return errors;
};
