import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";
import { Form, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { serviceComission } from "../../../ApiServices/Apifun";

const ServiceComission = () => {
  const dispatch = useDispatch();
  const [comissionData, setComissionData] = useState({});
  const [searchComissionData, setSearchComissionData] = useState({});
  const [serviceName, setServiceName] = useState("");
  const [pageLoader, setPageLoader] = useState(false);
  const getComission = () => {
    setPageLoader(true);
    serviceComission()
      .then((res) => {
        if (res.status) {
          setComissionData(res.data);
          setSearchComissionData(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("10001"));
    getComission();
  }, []);
  const handleSearch = () => {
    let data = { ...searchComissionData };
    if (serviceName !== "") {
      const filteredObj = Object.keys(data).reduce((acc, key) => {
        if (key?.toLowerCase()?.includes(serviceName?.toLowerCase())) {
          acc[key] = data[key];
        }
        return acc;
      }, {});
      setComissionData(filteredObj);
    } else {
      setComissionData(searchComissionData);
    }
  };
  return (
    <>
      <Spin spinning={pageLoader}>
        <p className="font-bold text-xl font-sans">Service Search</p>
        <div className="rounded-md mt-2 px-2 h-14  md:w-1/2 flex justify-between items-center  bg-inputbg ">
          <input
            onChange={(e) => setServiceName(e.target.value)}
            value={serviceName}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            placeholder="Search here..."
            className="outline-none border-none w-full px-4 text-sm bg-inputbg "
          />
          <SearchOutlined
            onClick={() => handleSearch()}
            className={`text-sm ${
              serviceName ? "cursor-pointer" : "cursor-not-allowed"
            } bg-bsgray600 text-bswhite border border-bsgray500 rounded-full w-10 h-9 flex justify-center items-center`}
          />
        </div>
        {Object.keys(comissionData).map((key, index) => {
          const getValueType = (item) => {
            if (item.hasOwnProperty("type")) {
              return item.type === "0" ? "Rs." : "%";
            } else if (item.hasOwnProperty("value_type")) {
              return item.value_type === "0" ? "Rs." : "%";
            } else {
              return "Rs.";
            }
          };
          return (
            <>
              <p className="font-bold text-sm mt-2">{key}</p>
              <div className="grid grid-cols-1 sm:grid-cols-2 mb-8 text-white  lg:grid-cols-3 place-items-center gap-2">
                {comissionData[key].map((i, index) => {
                  return (
                    <div className="bg-white border border-primary w-full rounded-md p-2 my-2">
                      {i.hasOwnProperty("start_range") && (
                        <p className="font-semibold capitalize">
                          <span className="text-white">Start Range:</span>{" "}
                          {i.start_range}
                        </p>
                      )}
                      {i.hasOwnProperty("end_range") && (
                        <p className="font-semibold capitalize">
                          <span className="text-white">End Range:</span>{" "}
                          {i.end_range}
                        </p>
                      )}
                      {i.hasOwnProperty("operator_name") && (
                        <p className="font-semibold capitalize">
                          <span className="text-white">Operator Name:</span>{" "}
                          {i.operator_name}
                        </p>
                      )}
                      {i.hasOwnProperty("service_name") && (
                        <p className="font-semibold capitalize">
                          <span className="text-white">Service Name:</span>{" "}
                          {i.service_name}
                        </p>
                      )}

                      {i.hasOwnProperty("operator_code") && (
                        <p className="font-semibold capitalize">
                          <span className="text-white"> Operator Code:</span>{" "}
                          {i.operator_code}
                        </p>
                      )}
                      {i.hasOwnProperty("surcharge_type") && (
                        <p className="font-semibold capitalize">
                          <span className="text-white">Surcharge Type</span>:{" "}
                          {i.surcharge_type}
                        </p>
                      )}
                      {i.hasOwnProperty("value") && (
                        <p className="font-semibold capitalize">
                          <span className="text-white">
                            {i.service_type === "both" && key === "BBPS"
                              ? "Bill Payment Surcharge"
                              : i.service_type}
                          </span>
                          : {i.value}
                          <span className="text-white">{getValueType(i)}</span>
                        </p>
                      )}
                      {i.hasOwnProperty("fetch_value") && (
                        <p className="font-semibold capitalize">
                          <span className="text-white">
                            {i.service_type === "both" && key === "BBPS"
                              ? "Bill Fetch Surcharge"
                              : i.service_type}
                          </span>
                          : {i.fetch_value}
                          {i.hasOwnProperty("type") ? (
                            i.type == "0" ? (
                              <span className="text-white">Rs.</span>
                            ) : (
                              <span className="text-white">%</span>
                            )
                          ) : i.type == "0" ? (
                            <span className="text-white">Rs.</span>
                          ) : (
                            <span className="text-white">%</span>
                          )}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </Spin>
    </>
  );
};

export default ServiceComission;
