import React, { useEffect, useState } from "react";
import PlanDetails from "./PlanDetail";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Select, Spin } from "antd";
import {
  GetNumberOperator,
  GetOperatorAndCircle,
  GetOperatorAndCircleDTH,
  GetOperatorAndCircleFASTAG,
  GetOperatorAndCircleGOOGLEPLAY
} from "../../../../ApiServices/Apifun";
import { useNavigate } from "react-router-dom";
import {
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
} from "../../../../Utils";
import { Globalendpoints } from "../../../../ApiServices/global";
import CommonInput from "../../../../Common/CommonInput";
import { DTH } from "../../../../Assets/dth";
import { Mobile_Prepaid } from "../../../../Assets/mobile prepaid";
import { ThemeColor } from "../../../../Theme/theme";
import { useDispatch } from "react-redux";
import { setselectedTab } from "../../../../Store/B2bslice";
import { bbpsFechbillPayment } from "../../BBPS2/Bbps2Endpoint";

const Prepaid = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [operatorAndCircle, setOperatorAndCircle] = useState({});
  const [circleWithCode, setCircleWithCode] = useState([]);
  const [operators, setOperators] = useState([]);
  const [amount, setamount] = useState(0);
  const [reqFields, setreqFields] = useState(false);
  const [billDetail, setBillDetail] = useState({});
  const [dthOperators, setDthOperators] = useState([]);
  const [fastagOperators, setfastagOperators] = useState([]);
  const [googlePlayOperators, setgooglePlayOperators] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [planDesc, setPlanDesc] = useState({
    validity: "",
    Data: "",
  });
  const [fields, setFields] = useState({
    mobile: "",
    circle: null,
    operator: null,
    amount: "",
    latitude: getLocalStorageItem("latitude"),
    longitude: getLocalStorageItem("longitude"),
  });
  const [selectedTab, setSelected] = useState(0);
  const tab = [
    { id: 1, name: "MOBILE" },
    { id: 2, name: "DTH" },
    { id: 3, name: "FASTAG" },
    { id: 4, name: "GOOGLEPLAY" },
  ];
  // const tabNames = [
  //   {
  //     name: "Postpaid",
  //     id: 1,
  //     image: <Mobile_Prepaid style={ThemeColor.bswhite} />,
  //     key: 23,
  //   },
  //   {
  //     name: "DTH",
  //     id: 2,
  //     image: <DTH style={ThemeColor.bswhite} />,
  //     key: 4,
  //   },
  // ];
  const [isLoading, setLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const getAllOperators = () => {
    setSelectLoading(true);
    GetOperatorAndCircle()
      .then((res) => {
        setOperatorAndCircle(res.data);
        const circlecode = res.data.circles_with_code.map((item, index) => {
          return {
            label: item.circle,
            value: item.circle,
            key: index,
          };
        });
        setCircleWithCode(circlecode);
        const OperatorOption = res.data.operators.map((i, index) => {
          return {
            label: (
              <div
                key={index}
                className="flex m-1 justify-start gap-3 items-center"
              >
                <img src={i.image} className="w-10 h-10" alt="operator_image" />
                <p className="font-semibold">{i.display}</p>
              </div>
            ),
            value: i.operator,
            key: index,
          };
        });
        setOperators(OperatorOption);
      })
      .catch((err) => console.log(err))
      .finally(() => setSelectLoading(false));
  };
  const getDthOperator = () => {
    setSelectLoading(true);
    GetOperatorAndCircleDTH()
      .then((res) => {
        setOperatorAndCircle(res.data);
        // const circlecode = res.data.circles_with_code.map((item, index) => {
        //   return {
        //     label: item.circle,
        //     value: item.circle,
        //     key: index,
        //   };
        // });
        // setCircleWithCode(circlecode);
        const OperatorOption = res.data.map((i, index) => {
          return {
            label: (
              <div
                key={index}
                className="flex m-1 justify-start gap-3 items-center"
              >
                <p className="font-semibold">{i.operator_name}</p>
              </div>
            ),
            value: i.operator_code,
            key: index,
          };
        });
        setDthOperators(OperatorOption);
      })
      .catch((err) => console.log(err))
      .finally(() => setSelectLoading(false));
  };

  const handleFetchBill = (val) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("opcode", fields.operator);
    formdata.append("biller_id", fields.mobile);
    // formdata.append("number", val.amount);
    // formdata.append("latitude", getLocalStorageItem("latitude"));
    // formdata.append("longitude", getLocalStorageItem("longitude"));
    formdata.append("service_type", "fasttag");
    // formdata.append("email", val.email);
    // formdata.append("dob", dayjs(val.dob).format("DD-MM-YYYY"));
    bbpsFechbillPayment(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setBillDetail(res.data);
          setreqFields(true);
          setamount(res.data.amount);

          // form.resetFields();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const getFatagOperator = () => {
    setSelectLoading(true);
    GetOperatorAndCircleFASTAG()
      .then((res) => {
        setOperatorAndCircle(res.data);
        // const circlecode = res.data.circles_with_code.map((item, index) => {
        //   return {
        //     label: item.circle,
        //     value: item.circle,
        //     key: index,
        //   };
        // });
        // setCircleWithCode(circlecode);
        const OperatorOption = res.data.map((i, index) => {
          return {
            label: (
              <div
                key={index}
                className="flex m-1 justify-start gap-3 items-center"
              >
                <p className="font-semibold">{i.operator_name}</p>
              </div>
            ),
            value: i.operator_code,
            key: index,
          };
        });
        setfastagOperators(OperatorOption);
      })
      .catch((err) => console.log(err))
      .finally(() => setSelectLoading(false));
  };
  const getGooglePlayOperator = () => {
    setSelectLoading(true);
    GetOperatorAndCircleGOOGLEPLAY()
      .then((res) => {
        setOperatorAndCircle(res.data);
        // const circlecode = res.data.circles_with_code.map((item, index) => {
        //   return {
        //     label: item.circle,
        //     value: item.circle,
        //     key: index,
        //   };
        // });
        // setCircleWithCode(circlecode);
        const OperatorOption = res.data.map((i, index) => {
          return {
            label: (
              <div
                key={index}
                className="flex m-1 justify-start gap-3 items-center"
              >
                <p className="font-semibold">{i.operator_name}</p>
              </div>
            ),
            value: i.operator_code,
            key: index,
          };
        });
        setgooglePlayOperators(OperatorOption);
      })
      .catch((err) => console.log(err))
      .finally(() => setSelectLoading(false));
  };
  useEffect(() => {
    if (selectedTab === 0) {
      getAllOperators();
    } else if (selectedTab == 1) {
      getDthOperator();
    }
    else if (selectedTab == 2) {
      getFatagOperator();
    }
    else if (selectedTab == 3) {
      getGooglePlayOperator();
    }
  }, [selectedTab]);
  useEffect(() => {
    setFields({
      mobile: "",
      circle: null,
      operator: null,
      amount: "",
      latitude: getLocalStorageItem("latitude"),
      longitude: getLocalStorageItem("longitude"),
    });
    setAllPlans([]);
    // setOperators([]);
    dispatch(setselectedTab("2"));
    setCircleWithCode([]);
    setOperatorAndCircle({});
  }, [selectedTab]);
  useEffect(() => {
    if (fields.mobile.length === 10 && selectedTab === 0) {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("mobile", fields.mobile);
      GetNumberOperator(formdata)
        .then((res) => {
          if (res.data.status) {
            setFields({
              ...fields,
              operator: res.data.operator,
              circle: res.data.circle,
            });
          } else {
            dispatchtoast(res.data.msg, true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [fields.mobile]);
  useEffect(() => {
    if (fields.amount) {
      let timer = setTimeout(() => {
        const plan = allPlans
          .map((i, item) => i.plans.flat(2))
          .flat()
          .filter((i) => i.price === Number(fields.amount));
        setPlanDesc({ validity: plan[0]?.validity, Data: plan[0]?.data });
      }, 500);
    }
  }, [fields.amount]);
  const getAmount = (amount) => {
    setFields({ ...fields, amount });
  };
  const handleRecharge = async () => {
    const payloadObj = {
      mobile: fields.mobile,
      circle: fields?.circle ?? selectedTab == 1 ? "DTH" : selectedTab == 2 ? "fastag" : "googleplay",
      operator:
        selectedTab === 0
          ? fields.operator
          : operatorAndCircle.find((i) => i.operator_code === fields.operator)
            .operator_name,
      amount: fields.amount,
      circle_code:
        selectedTab === 0
          ? operatorAndCircle.circles_with_code.filter(
            (item, index) => item.circle === fields.circle
          )[0]?.code
          : 20,
      operator_code:
        // fields.operator === "Vodafone Idea"
        //   ? operatorAndCircle.operators.filter(
        //       (item, index) => item.operator === "Vi"
        //     )[0].code
        //   :
        selectedTab === 0
          ? operatorAndCircle.operators.filter(
            (item, index) => item.operator === fields.operator
          )[0]?.code
          : fields.operator,
      latitude: getLocalStorageItem("latitude"),
      longitude: getLocalStorageItem("longitude"),
    };
    const operatorIcon =
      // fields.operator === "Vodafone Idea"
      //   ? operatorAndCircle.operators.filter(
      //       (item, index) => item.operator === "Vi"
      //     )[0].image
      //   :
      selectedTab === 0
        ? operatorAndCircle.operators.filter(
          (item, index) => item.operator === fields.operator
        )[0]?.image
        : null;
    navigate("/checkOut", {
      state: {
        payload: payloadObj,
        apiEndpoint: Globalendpoints.recharge,
        service: selectedTab === 0 ? "Recharge" : selectedTab === 1 ? "DTH" : selectedTab === 2 ? "Fastag" : "GooglePlay",
        amount: fields.amount,
        icon: operatorIcon,
        selectedTab: selectedTab,
      },
    });
  };

  return (
    <>
      {/* <div className="All_services bg-white p-10 rounded-md mb-5 gap-8 flex justify-start flex-wrap items-center">
    {tabNames.map((item, index) => {
      return (
        <div key={index}>
          <div
            onClick={() => setSelected(index)}
            className=" p-3 w-14 h-14 hover:scale-110  cursor-pointer hover:opacity-100 bg-primary rounded-full hover:bg-primaryhover text-white transition-all flex flex-col justify-center items-center font-bold  "
          >
            {item.image}
          </div>
          <p className={`text-[13px]    font-semibold text-center`}>
            {item.name}
          </p>
          { item.id - 1 === selectedTab ? (
            <div className="animate-slide-in mt-1 border border-primary "></div>
          ) : (
            ""
          )}
        </div>
      );
    })}
  </div> */}
      <div className="grid grid-cols-1 md:grid-cols-3 w place-items-start gap-3">
        <Spin className="w-full" spinning={isLoading}>
          <div className="prepaid bg-white w-full rounded-3xl  shadow-normal  ">
            <div className="  bg-white rounded-lg grid grid-cols-4 place-items-start text-sm md:place-items-center gap-y-4">
              {tab.map((i, index) => {
                return (
                  <div className="w-full text-center">
                    <p
                      onClick={() => {
                        setSelected(index);
                      }}
                      className={`cursor-pointer text-xs  transition-all ${index === 0 ? "rounded-tl-lg transition-all" : "rounded-tr-lg transition-all"}  ${i.id - 1 === selectedTab
                        ? "bg-primary font-semibold  tab-active text-white"
                        : "  "
                        }  py-2 `}
                    >
                      {i.name}
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="w-full h-[1px] bg-primary"></div>
            <div className="p-5">
              <p className="font-bold mt-2  text-[15px] mb-2">
                {selectedTab === 0 ? "Recharge Mobile" : selectedTab === 1 ? "Recharge DTH" : selectedTab === 2 ? "Recharge FASTAG" : "Recharge GooglePlay"}
              </p>
              <div className="selectionFields ">

                {selectedTab === 2 ?
                  <div>
                    <p className="text-textGray required mb-2">
                      Vechical No.
                    </p>

                    <CommonInput
                      placeholder={
                        " Vechical No."
                      }
                      value={fields.mobile}
                      maxlength={selectedTab === 0 ? 10 : 15}
                      onInput={(e) => {
                        if (selectedTab === 0) {
                          const value = e.target.value.replace(/\D/g, "");
                          e.target.value = value;
                        }
                      }}
                      onChange={(e) =>
                        setFields({ ...fields, mobile: e.target.value })
                      }
                    />
                  </div>
                  :
                  <div>
                    <p className="text-textGray required mb-2">
                      {selectedTab === 0 ? "Mobile Number" : "Account Id"}
                    </p>

                    <CommonInput
                      placeholder={
                        selectedTab === 0
                          ? "Enter Mobile Number"
                          : "Enter Account Id"
                      }
                      value={fields.mobile}
                      maxlength={selectedTab === 0 ? 10 : 15}
                      onInput={(e) => {
                        if (selectedTab === 0) {
                          const value = e.target.value.replace(/\D/g, "");
                          e.target.value = value;
                        }
                      }}
                      onChange={(e) =>
                        setFields({ ...fields, mobile: e.target.value })
                      }
                    />
                  </div>
                }


                <div>
                  <p className="text-textGray required mb-2">Operator</p>
                  <Select
                    placeholder="Select operator"
                    onChange={(val) => {
                      setFields({ ...fields, operator: val });
                    }}
                    showSearch
                    loading={selectLoading}
                    value={fields.operator}
                    placement="bottomRight"
                    className="w-full operatorSelect text-xs mb-2 h-auto"
                    options={selectedTab === 0 ? operators : selectedTab === 1 ? dthOperators : selectedTab === 2 ? fastagOperators : googlePlayOperators}
                  />
                </div>
                {selectedTab === 0 && (
                  <div>
                    <p className="text-textGray required mb-2">Circle</p>
                    <Select
                      showSearch
                      placeholder="Select circle"
                      value={fields.circle}
                      filterOption={filterOption}
                      onChange={(val) => {
                        setFields({ ...fields, circle: val });
                      }}
                      loading={selectLoading}
                      className="w-full mb-2"
                      options={circleWithCode}
                    />
                  </div>
                )}
                <div>
                  <p className="text-txtGray required mb-2">Amount</p>
                  <CommonInput
                    placeholder="Enter Amount"
                    value={fields.amount}
                    min={1}
                    onInput={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      e.target.value = value;
                    }}
                    // disabled={!allPlans.length && selectedTab === 0}
                    onChange={(e) =>
                      setFields({ ...fields, amount: e.target.value })
                    }
                  />
                </div>
                {
                  selectedTab == 0 &&
                  <div>
                    <div className="border text-xs font-medium cursor-pointer mt-3 flex flex-wrap justify-center items-center p-1 gap-2 rounded-full border-bsgray400">
                      <div className="flex gap-1 flex-wrap justify-center items-center">
                        <CheckCircleTwoTone color="green" className="" />
                        <p>Validity:{planDesc.validity}</p>
                      </div>
                      <div className="flex gap-1 flex-wrap justify-center items-center">
                        <CheckCircleTwoTone />
                        <p>Data:{planDesc.Data}</p>
                      </div>
                    </div>
                  </div>

                }

                {reqFields && (
                  <div className="grid mt-2 grid-cols-1 border text-white border-primary p-2 rounded-md font-medium text-xs bg-bsgray300 sm:grid-cols-2 gap-2 place-items-start">
                    <p>Name : {billDetail.userName ? billDetail.userName : "-"}</p>
                    {services[current]["key"] !== "fasttag" ? (
                      <>
                        <p>Amount : {billDetail.amount ? billDetail.amount : "-"} Rs.</p>
                        <p>Bill Date : {billDetail.bill_date ? billDetail.bill_date : "-"}</p>
                        <p>Due Date : {billDetail.due_date ? billDetail.due_date : "-"}</p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {/* ) : fields.amount &&
                  !planDesc.validity &&
                  !planDesc.Data &&
                  selectedTab === 0 ? (
                  <p className="text-bsred tetx-xs">
                    Please select a valid plan!
                  </p>
                ) : (
                  ""
                )} */}
                <div className="">
                  <p>&nbsp;</p>
                  <Button
                    disabled={
                      selectedTab === 0
                        ? !fields.amount ||
                        !fields.circle ||
                        !fields.mobile ||
                        fields.mobile.length !== 10 ||
                        !fields.operator
                        //  ||
                        // (!planDesc.validity && !planDesc.Data)
                        : !fields.amount ||
                        // !fields.circle ||
                        !fields.mobile ||
                        !fields.operator
                      // (!planDesc.validity && !planDesc.Data)
                    }
                    onClick={() => handleRecharge()}
                    className="bg-primary w-full border-none  hover:outline-none rounded-md h-9 text-white text-center flex justify-center items-center font-bold text-md cursor-pointer"
                  >
                    Proceed To Rechage
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Spin>
        {selectedTab === 0 && (
          <PlanDetails
            setAllPlans={setAllPlans}
            getAmount={getAmount}
            operator={fields.operator}
            circle={fields.circle}

          />
        )}
      </div>
    </>
  );
};

export default Prepaid;
