import { Button, Form, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CommonInput from "../../../Common/CommonInput";
import { applyTataDthConnection, dthConnectionPlans, getbbps2Services } from "./Bbps2Endpoint";
import {
  dispatchtoast,
  emailPattern,
  getLocalStorageItem,
} from "../../../Utils";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";

const BBps2Tata = () => {
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();
  const [dthConnectionAllPlans, setDthConnectionPlans] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planLoader, setPlanLoader] = useState(false);
  const [form] = Form.useForm();
  const [allServices, setAllServices] = useState([]);

  const services = allServices?.filter((i) => i.operator_type === "3")

  const handlePayBill = (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("first_name", val.first_name);
    formdata.append("last_name", val.last_name);
    formdata.append("amount", val.amount);
    formdata.append("plan_id", selectedPlan);
    formdata.append("full_address", val.full_address);
    formdata.append("postal_code", val.postal_code);
    formdata.append("number", val.amount);
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    formdata.append("service_type", services[current]["key"]);
    formdata.append("email", val.email);
    formdata.append("dob", dayjs(val.dob).format("DD-MM-YYYY"));
    applyTataDthConnection(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          form.resetFields();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  const getDthPlans = () => {
    setPlanLoader(true);
    dthConnectionPlans()
      .then((res) => {
        if (res.status) {
          setDthConnectionPlans(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPlanLoader(false));
  };
  const getAllServices = () => {
    getbbps2Services()
      .then((res) => {
        if (res.status) {
          setAllServices(res.data);
        }
      })
      .catch((err) => console.log(res));
  };
  useEffect(() => {
    dispatch(setselectedTab("2014"));
    getAllServices();
  }, []);
  useEffect(() => {
    getDthPlans();
  }, []);
  return (
    <>
      <div className="flex w-full bg-white p-2 rounded-lg justify-start items-center overflow-x-scroll gap-x-2">
        {services.map((i, index) => {
          return (
            <div
              onClick={() => setCurrent(index)}
              className="cursor-pointer w-full"
            >
              <p
                key={index}
                className={`min-w-[130px] lg:w-full text-start ${index === current ? "font-bold text-primary" : "font-medium"
                  }`}
              >
                {i?.service_name}
              </p>
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 place-items-start gap-3">
        <div className="w-full  p-4 bg-white mt-4 rounded-md  ">
          <Form
            form={form}
            onFinish={(val) => handlePayBill(val)}
            labelCol={{ span: 24 }}
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter first name",
                },
              ]}
              label={"Enter First Name"}
              name="first_name"
            >
              <CommonInput placeholder={"Enter first name"} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter last name",
                },
              ]}
              label={"Enter Last Name"}
              name="last_name"
            >
              <CommonInput placeholder={"Enter last name"} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter amount",
                },
              ]}
              label={"Enter Amount"}
              name="amount"
            >
              <CommonInput
                disabled
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
                placeholder={"Enter amount"}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter number",
                },
              ]}
              label={"Enter Number"}
              name="number"
            >
              <CommonInput
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
                placeholder={"Enter number"}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  pattern: emailPattern,
                  message: "Enter valid email",
                },
              ]}
              label={"Enter Email"}
              name="email"
            >
              <CommonInput placeholder={"Enter email"} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter full address",
                },
              ]}
              label={"Enter Full Address"}
              name="full_address"
            >
              <CommonInput placeholder={"Enter full address"} />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter postal code",
                },
              ]}
              label={"Enter Postal Code"}
              name="postal_code"
            >
              <CommonInput placeholder={"Enter postal_code"} />
            </Form.Item>
            <Form.Item className="w-full">
              <Button
                loading={btnLoading}
                htmlType="submit"
                className="bg-primary text-white w-full mt-2 border-none"
              >
                Pay Bill
              </Button>
            </Form.Item>
          </Form>
        </div>{" "}
        <div className="w-full  p-4 bg-white mt-4 rounded-md  min-h-[600px] max-h-[600px] overflow-y-scroll ">
          <Spin spinning={planLoader}>
            {dthConnectionAllPlans?.length > 0 ? (
              dthConnectionAllPlans.map((i, index) => {
                return (
                  <div key={index} className="mt-1">
                    <p className="font-semibold">{i?.details}</p>
                    <div className="flex justify-end items-center">
                      <p
                        onClick={() => {
                          form.setFieldValue("amount", i.amount);
                          setSelectedPlan(i.plan_id);
                        }}
                        className="bg-primary cursor-pointer text-white px-4 py-2 mt-2 rounded-md text-end"
                      >
                        ₹ {i.amount}
                      </p>
                    </div>
                    <hr className="mt-2" />
                  </div>
                );
              })
            ) : (
              <div className="flex justify-center items-center mt-36 font-bold text-black text-xl">
                <p>No plans found</p>
              </div>
            )}
          </Spin>
        </div>
      </div>
    </>
  );
};

export default BBps2Tata;
