import { Button, DatePicker, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import CommonInput from "../../../Common/CommonInput";
import { bbpsbillPayment, getOperators, bbpsFechbillPayment } from "./Bbps2Endpoint";
import {
  dispatchtoast,
  emailPattern,
  filterOption,
  getLocalStorageItem,
  predefinedOptions,
} from "../../../Utils";
import dayjs from "dayjs";

const BBps2Services = ({ services }) => {
  const [current, setCurrent] = useState(0);
  const [amount, setamount] = useState(0);
  const [reqFields, setreqFields] = useState(false);
  const [selectLoader, setSelectLoader] = useState(false);
  const [service, setservice] = useState(false);
  const [disableAmount, setDisableAmount] = useState(false);
  const [allOperators, setAllOperators] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [billDetail, setBillDetail] = useState({});
  const [dynamicFeilds, setdynamicFeilds] = useState({

    "mobileNumber": "0",
    "dateofBirth": "0",
    "emailId": "0",
    "registrationNumber": "0",
    "customerAccountNumber": "0",
    "circleID": "0",
    "subdivisionCode": "0",
    "billId": "0",
    "billType": "0",
    "billDeskTxnId": "0",
    "billDeskValidationId": "0",
    "paymentType": "0",
    "consumerType": "0",
    "propId": "0",
    "assessmentYear": "0",
    "houseNumber": "0",
    "loanRepaymentMode": "0",
    "connectionNo": "0",
    "localBody": "0",
    "loanNumber": "0",
    "yearMonth": "0",
    "quoteID": "0",
    "productGroup": "0",
    "pledgeValue": "0",
    "wardNo": "0",
    "emi_close": "0",
    "productQty": "0",
    "svNumber": "0",
    "partitionNumber": "0",
    "bookingmethod": "0",
    "vehicleNumber": "0",
    "policytype": "0",
    "overdueandcharges": "0",
    "vc_card_franchisee_number": "0",
    "productName": "0",
    "tenantno": "0",
    "ViewBill": "0"
  });
  const [selectedOperator, setselectedOperator] = useState(null);
  const [form] = Form.useForm();
  const [showRegistrationNumber, setShowRegistrationNumber] = useState(false);
  let dateOfDob
  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.format("DD-MM-YYYY");
      dateOfDob = formattedDate
      console.log("Selected Date:", formattedDate); // Save or handle the formatted date
    }
  };
  const getAllOperators = (view) => {
    setSelectLoader(true);

    const formdata = new FormData();
    formdata.append("service_key", services[current]?.["key"]);
    formdata.append("operator_type", services[0]?.["operator_type"]);

    getOperators(formdata)
      .then((res) => {
        if (res.status) {
          const operators = res.data?.operators;

          // If operators is an object, convert it into an array
          const operatorsArray = Array.isArray(operators) ? operators : [operators];

          // Transform operators for setting all operators
          setAllOperators(
            operatorsArray.map((i) => ({
              label: i?.operator_name,
              value: i?.operator_code,
            }))
          );

          // Transform operators for dynamic fields
          if (view) {
            setdynamicFeilds(
              operatorsArray.map((i) => ({
                operator_name: i?.operator_name,
                operator_code: i?.operator_code,
                ViewBill: i?.ViewBill,
                mobileNumber: i?.mobileNumber,
                dateofBirth: i?.dateofBirth,
                emailId: i?.emailId,
                registrationNumber: i?.registrationNumber,
                customerAccountNumber: i?.customerAccountNumber,
                circleID: i?.circleID,
                subdivisionCode: i?.subdivisionCode,
                billId: i?.billId,
                billType: i?.billType,
                billDeskTxnId: i?.billDeskTxnId,
                billDeskValidationId: i?.billDeskValidationId,
                paymentType: i?.paymentType,
                consumerType: i?.consumerType,
                propId: i?.propId,
                assessmentYear: i?.assessmentYear,
                houseNumber: i?.houseNumber,
                loanRepaymentMode: i?.loanRepaymentMode,
                connectionNo: i?.connectionNo,
                localBody: i?.localBody,
                loanNumber: i?.loanNumber,
                yearMonth: i?.yearMonth,
                quoteID: i?.quoteID,
                productGroup: i?.productGroup,
                pledgeValue: i?.pledgeValue,
                wardNo: i?.wardNo,
                emi_close: i?.emi_close,
                productQty: i?.productQty,
                svNumber: i?.svNumber,
                partitionNumber: i?.partitionNumber,
                bookingmethod: i?.bookingmethod,
                vehicleNumber: i?.vehicleNumber,
                policytype: i?.policytype,
                overdueandcharges: i?.overdueandcharges,
                vc_card_franchisee_number: i?.vc_card_franchisee_number,
                productName: i?.productName,
                tenantno: i?.tenantno,
              }))
            );
          }
          else {
            setdynamicFeilds(
              operatorsArray.map((i) => ({}))
            );
          }


        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSelectLoader(false));
  };
  // const handleSubmit = (val) => {
  //   const currentField = dynamicFeilds.find(
  //     (field) => field.operator_code === selectedOperator
  //   );
  //   if (currentField?.ViewBill === "1") {
  //     console.log("Fetching Bill", val);
  //   } else {
  //     console.log("Paying Bill", val);
  //   }
  // };
  // console.log(typeof dynamicFeilds);
  // Predefined options for specific keys
console.log(selectedOperator);
  const renderDynamicFields = () => {
    if (selectedOperator && dynamicFeilds) {
      // Find the object that matches the selectedOperator
      const matchedField = Array.isArray(dynamicFeilds) && dynamicFeilds.find(field => field.operator_code === selectedOperator);

      // If a match is found, proceed to filter the keys with value "1"
      if (matchedField) {
        return Object.entries(matchedField) // Get the key-value pairs of the matched object
          .filter(([key, value]) => value === "1" && key != "ViewBill") // Filter for keys with value "1"
          .map(([key, value]) => {
            // Render dropdown for predefined options
            if (predefinedOptions[key]) {
              return (
                <Form.Item
                  key={key}
                  name={key}
                  label={`Select ${key}`}
                  rules={[{ required: true, message: `Please select ${key}` }]}
                >
                  <Select
                    showSearch
                    options={predefinedOptions[key]}
                    placeholder={`Select ${key}`}
                    allowClear
                  />
                </Form.Item>
              );
            }
            if (key == "dateofBirth") {
              return (
                <Form.Item
                  // name={`dateofBirth`}
                  label={"Date Of Birth"}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Enter Date Of Birth!",
                    },
                  ]}
                >
                  <DatePicker
                    popupClassName="bottom-calendar"
                    className="w-full datepicker h-9"
                    onChange={handleDateChange}
                    placement="bottomLeft"
                    placeholder="Enter DOB"
                    format="DD-MM-YYYY" // Display the date in DD-MM-YYYY format
                  />
                </Form.Item>
              );
            }

            // Render input field for other keys
            return (
              <Form.Item
                key={key}
                name={key}
                label={`Enter ${key}`}
                rules={[{ required: true, message: `Please enter ${key}` }]}
              >
                <input
                  type="text"
                  placeholder={`Enter ${key}`}
                  className="outline-none text-xs border w-full mb-2 border-gray-200 focus:border-primary h-9 px-2"
                />
              </Form.Item>
            );
          });
      }
    }

    return null; // Return nothing if no match is found or selectedOperator is missing
  };


  useEffect(() => {
    if (services.length > 0) {
      getAllOperators(false);
      setreqFields(false);
    }
  }, [current, services]);
  useEffect(() => {
    setAllOperators([]);
    form.resetFields();
    setShowRegistrationNumber(false);
  }, [current]);
  // useEffect(() => {
  //   setreqFields(false);
  // }, [services]);
  const handleFetchBill = (val) => {
    setBtnLoading(true);
    const formdata = new FormData();
    // formdata.append("opcode", val.opcode);
    // formdata.append("biller_id", val.number);
    Object.entries(val).map(([key, value], index) =>
    formdata.append(key, value)
  );
    // formdata.append("number", val.amount);
    // formdata.append("latitude", getLocalStorageItem("latitude"));
    // formdata.append("longitude", getLocalStorageItem("longitude"));
    formdata.append("service_type", services[current]["key"]);
    if(dateOfDob){
      formdata.append("dateofBirth", dateOfDob);
    }
    // formdata.append("email", val.email);
    // formdata.append("dob", dayjs(val.dob).format("DD-MM-YYYY"));
    bbpsFechbillPayment(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setBillDetail(res.data);
          setreqFields(true);
          setamount(res.data.amount);
          setDisableAmount(true)


          // form.resetFields();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  const handlePayBill = (val) => {
    setBtnLoading(true);

    const formdata = new FormData();
    Object.entries(val).map(([key, value], index) =>
      formdata.append(key, value)
    );
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    formdata.append("service_type", services[current]["key"]);
    formdata.append("amount", amount);
    if(dateOfDob){
      formdata.append("dateofBirth", dateOfDob);
    }
    bbpsbillPayment(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setamount(0);
          setreqFields(false);
          form.resetFields();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  return (
    <>
      <div className="flex w-full bg-white p-2 rounded-lg justify-start items-center overflow-x-scroll gap-x-2">
        <div className="flex relative w-full items-center">
          {/* Left Button */}
          <button
            className="absolute left-[-9px] p-2 bg-primary text-white z-10"
            onClick={() => {
              const container = document.getElementById("scrollContainer");
              if (container) container.scrollLeft -= 150; // Adjust scroll amount
            }}
          >
            {"<"}
          </button>

          {/* Scrollable Content */}
          <div
            id="scrollContainer"
            className="flex w-full mx-9 overflow-x-scroll gap-x-2"
            style={{ scrollBehavior: "smooth" }}
          >
            {services.map((i, index) => (
              <div
                key={index}
                onClick={() => {
                  setCurrent(index);
                  setservice(i?.service_name)
                  setselectedOperator(null);
                  setBtnLoading(false);
                }}
                className="cursor-pointer w-full"
              >
                <p
                  className={`min-w-[130px] lg:w-full text-center ${index === current ? "font-bold text-primary" : "font-medium"
                    }`}
                >
                  {i?.service_name}
                </p>
              </div>
            ))}
          </div>

          {/* Right Button */}
          <button
            className="absolute right-[-9px] p-2 bg-primary text-white z-10"
            onClick={() => {
              const container = document.getElementById("scrollContainer");
              if (container) container.scrollLeft += 150; // Adjust scroll amount
            }}
          >
            {">"}
          </button>
        </div>
      </div>

      <div className="w-full md:w-1/2 p-4 bg-white mt-4 rounded-md ">
        <Form
          form={form}
          onFinish={(val) => !reqFields ? handleFetchBill(val) : handlePayBill(val)}
          labelCol={{ span: 24 }}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select operator",
              },
            ]}
            label={"Select Operator"}
            name="opcode"
          >
            <Select
              showSearch
              filterOption={filterOption}
              onSelect={() => { getAllOperators(true); }}
              // onClick={()=>{getAllOperators(true);}}
              loading={selectLoader}
              options={allOperators}
              value={selectedOperator}
              // disabled={disableOperatorField}
              onChange={(val) => {
                val == "405"
                  ? setShowRegistrationNumber(true)
                  : setShowRegistrationNumber(false); setselectedOperator(val)
              }
              }
              placeholder="Select operator"
            />
          </Form.Item>
          {/* Dynamic Fields */}

          {renderDynamicFields()}

          {/* {reqFields &&
            <>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter amount",
                  },
                ]}
                label={"Enter Amount"}
                name="amount"
              >
                <input
                  value={amount}
                  defaultValue={amount}
                  className="outline-none text-xs border w-full mb-2 border-gray-200 focus:border-primary  h-9 px-2"
                  // min={1}
                  // disabled={disableAmount && props.text !== "fasttag"}
                  onChange={(e) => setamount(e.target.value)}
                  onInput={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    e.target.value = value;
                  }}
                  placeholder={"Enter amount"}
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                  {
                    pattern: emailPattern,
                    message: "Enter valid email",
                  },
                ]}
                label={"Enter Email"}
                name="email"
              >
                <CommonInput placeholder={"Enter email"} />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please enter dob",
                  },
                ]}
                className="w-full"
                label={"Enter Dob"}
                name="dob"
              >
                <DatePicker className="w-full" />
              </Form.Item>
            </>



          } */}
          {reqFields &&
            <>
              <Form.Item
                rules={[
                  {
                    required: amount ? false : true,
                    message: "Please enter amount",
                  },
                ]}
                label={"Enter Amount"}
              // name="amount"
              >
                <input
                  value={amount}
                  defaultValue={amount}
                  min={1}
                  disabled={disableAmount}
                  onChange={(e) => setamount(e.target.value)}
                  type="number"
                  className="outline-none text-xs border w-full border-gray-200 focus:border-primary  h-9 px-2 "
                />
              </Form.Item>
            </>



          }


          <Form.Item
            rules={[
              {
                required: true,
                message: "Please enter number",
              },
            ]}
            label={`${ service == "Insurance" ? "Enter Policy Number" : "Enter Bill number" }`}
            name="number"
          >
            <CommonInput
              onInput={(e) => {
                // const value = e.target.value.replace(/\D/g, "");
                e.target.value
              }}
              placeholder={`${ service == "Insurance" ? "Enter Policy Number" : "Enter Bill number" }`}
            />
          </Form.Item>


          {/* {showRegistrationNumber && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter registrationNumber",
                },
              ]}
              className="w-full"
              label={"Enter Registration Number"}
              name="registrationNumber"
            >
              <CommonInput
                placeholder={"Enter registration number"}
              />
            </Form.Item>
          )} */}
          {reqFields && (
            <div className="grid mt-2 grid-cols-1 border text-white border-primary p-2 rounded-md font-medium text-xs bg-bsgray300 sm:grid-cols-2 gap-2 place-items-start">
              <p>Name : {billDetail.userName ? billDetail.userName : "-"}</p>
              {services[current]["key"] !== "fasttag" ? (
                <>
                  <p>Amount : {billDetail.amount ? billDetail.amount : "-"} Rs.</p>
                  <p>Bill Date : {billDetail.bill_date ? billDetail.bill_date : "-"}</p>
                  <p>Due Date : {billDetail.due_date ? billDetail.due_date : "-"}</p>
                </>
              ) : (
                ""
              )}
            </div>
          )}
          <Form.Item className="w-full">
            {selectedOperator ? (
              Array.isArray(dynamicFeilds) && dynamicFeilds.find((field) => field.operator_code === selectedOperator)
                ?.ViewBill === "1" && !reqFields ? (
                <Button
                  loading={btnLoading}
                  htmlType="submit"
                  className="bg-primary text-white w-full mt-2 border-none"
                >
                  Fetch Bill
                </Button>
              ) : (
                <Button
                  loading={btnLoading}
                  htmlType="submit"
                  className="bg-primary text-white w-full mt-2 border-none"
                >
                  Pay Bill
                </Button>
              )
            ) : (
              <Button disabled className="w-full mt-2">
                Select an Operator
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default BBps2Services;
