import { HashRouter, useRoutes } from "react-router-dom";
import "./App.css";
import { PrivateAuth } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/PublicRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainerMsg, removeLocalStorageItem, setLocalStorageItem } from "./Utils";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import useDigiLoader from "./Hooks/useDigiLoader";
import axios from "axios";
import { ThemeColor } from "./Theme/theme";
function App() {
  const B2BRouting = [...PublicRoutes(), ...PrivateAuth()];
  const B2BRouteConfig = () => useRoutes(B2BRouting);
  const loader = useDigiLoader();

  const [color, setColor] = useState("#116af9");
  const [loading,setLoading]=useState(false)

  useEffect(() => {
    if (loader === "2") {
      removeLocalStorageItem("startLoader");
    }
    return () => removeLocalStorageItem("startLoader");
  }, [loader]);
  const getDetail = async () => {
    try {
      setLoading(true)
      const data = await axios.post(
        `https://${window?.location?.host.split('.').slice(1).join('.')}/auth/getAdminDetail`,
        {
          domain_name: window?.location?.host.split('.').slice(1).join('.'),
          // domain_name: "reseller.ezulix.co",
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data.data.status) {
        setColor(data.data?.adminData?.color_code);
        // document.documentElement.style.setProperty('--primary', data.data?.adminData?.color_code);
        setLocalStorageItem("detail", data.data.adminData);
      } else {
        console.log("err");
      }
    } catch (error) {
      console.log(error);
    }finally{
setLoading(false)
    }
  };
  useEffect(() => {
    getDetail();
  }, []);
  useEffect(() => {
    document.documentElement.style.setProperty("--primary-hover", color);
    document.documentElement.style.setProperty("--primary", color);
    document.documentElement.style.setProperty("--secondary", color);

  }, [color]);
  return (
    <>
      <HashRouter>
        <Spin spinning={loader === "1"}>
          <B2BRouteConfig />
        </Spin>
      </HashRouter>
      <ToastContainerMsg />
    </>
  );
}

export default App;
