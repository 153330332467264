import { Button, Form } from "antd";
import React, { useState } from "react";
import CommonInput from "../../../Common/CommonInput";
import {  dispatchtoast } from "../../../Utils";
import Content from "../../../Common/ReceiptContent";
import UpiOtpModal from "./UpiOtpModal";
import ReportDrawer from "../../../Common/ReportDrawer";
import { getPayoutAndDmtOtp } from "../../../ApiServices/Apifun";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setselectedTab } from "../../../Store/B2bslice";

const UpiForm = () => {
    const navigate=useNavigate()
    const dispatch=useDispatch()
  const memberServices = useSelector((state) => state.B2B.memberServices);
  const [btnLoading, setBtnLoading] = useState(false);
  const [allData, setAllData] = useState({});
  const [result, setResult] = useState({ data: null, transaction_id: null });
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [openReceiptDrawer, setOpenReceiptDrawer] = useState(false);
  const handlePay = (val) => {
    setBtnLoading(true);
    getPayoutAndDmtOtp()
      .then((res) => {
        if (res.status) {
          dispatchtoast("Otp Sent Successfull!");
          setAllData({ ...val, otp_uid: res.otp_uid });
          setOpenOtpModal(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  useEffect(() => {
    dispatch(setselectedTab("18"));
    if (!memberServices.includes("21")) {
      navigate("/not-Available");
    } 
  }, []);
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2 bg-white shadow-normal  rounded-3xl p-5">
          <p className="font-bold text-[15px] mb-2">Upi Dmt</p>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handlePay(val)}
          >
            <Form.Item
              name={`benificiary_upi`}
              label={"Upi I'd"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Your Upi I'd!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Upi I'd"
                name="benificiary_upi"
              />
            </Form.Item>
            <Form.Item
              name={`benificiary_name`}
              label={"Beneficiary Name"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Name!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput placeholder="Enter Name" name="benificiary_name" />
            </Form.Item>{" "}
            <Form.Item
              name={`amount`}
              label={"Amount"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter amount!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter amount"
                name="amount"
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>{" "}
            <Form.Item>
              <Button
                loading={btnLoading}
                className="w-full bg-primary border-none mt-4"
                style={{ color: "white" }}
                htmlType="submit"
              >
                Pay
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {openOtpModal && (
        <UpiOtpModal
          setResult={setResult}
          setOpenReceiptDrawer={setOpenReceiptDrawer}
          isOpen={openOtpModal}
          setIsOpen={setOpenOtpModal}
          otherFields={allData}
        />
      )}
      {openReceiptDrawer && (
        <ReportDrawer
          title={"Upi Dmt Receipt"}
          open={openReceiptDrawer}
          setOpen={setOpenReceiptDrawer}
          content={<Content data={result} />}
        />
      )}
    </>
  );
};

export default UpiForm;
