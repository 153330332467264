import { Button, DatePicker, Form, Select, Skeleton, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../../../../Theme/theme";
import { BiSolidUser } from "react-icons/bi";
import { AiFillBank, AiFillCheckCircle } from "react-icons/ai";
import { IoIosBusiness } from "react-icons/io";

import { HiDocument } from "react-icons/hi";
import {
  YesBankAepsOboard,
  getAllAepsMasters,
} from "../../../../../ApiServices/Apifun";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  clearOnboardDetail,
  setselectedTab,
} from "../../../../../Store/B2bslice";
import {
  aadharPattern,
  dispatchtoast,
  emailPattern,
  filterOption,
  mobilePattern,
  panPattern,
} from "../../../../../Utils";
import CommonInput from "../../../../../Common/CommonInput";
import dayjs from "dayjs";
import YesValidateOtpModal from "./YesValidateOtp";
import { Images } from "../../../../../Controller";

const AepsYesOnboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const handleSubmit = (val) => {
    let data = { ...val };
    data = { ...data, dob: dayjs(val.dob).format("DD-MM-YYYY") };
    setBtnLoading(true);
    const formdata = new FormData();
    Object.keys(data).map((key) => formdata.append(key, data[key]));
    // formdata.append("dob", dayjs(val.dob).format("YYYY-MM-DD"));
    YesBankAepsOboard(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setOpenOtpModal(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBtnLoading(false);
      });
  };
  useEffect(() => {
    dispatch(setselectedTab("17"));
  }, []);
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 place-items-start">
        <div className="prepaid animate-slide-in w-full md:w-1/2 bg-white shadow-normal  rounded-3xl p-5">
          <div className="flex justify-between items-center">
            <p className="text-[15px] font-bold ">Merchant Onboarding</p>
            <img
              src={Images.yesBank}
              className="flex justify-center  items-center w-32"
              alt="yes bank"
            />
          </div>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handleSubmit(val)}
          >
            <Form.Item
              name={`name`}
              label={"Name"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Name!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput placeholder="Enter Your Namer" name="name" />
            </Form.Item>
            <Form.Item
              name={`mobile`}
              label={"Mobile"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Mobile!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Mobile Number"
                name="mobile"
              />
            </Form.Item>
            <Form.Item
              name={`email`}
              label={"Email"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Email!",
                },
                {
                  pattern: emailPattern,
                  message: "Invalid email format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput placeholder="Enter Your Email" name="email" />
            </Form.Item>
            <Form.Item
              name={`pan`}
              label={"Pan Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Pan Number!",
                },
                {
                  pattern: panPattern,
                  message: "Invalid pan number format!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Pan Number"
                name="pan"
                onInput={(e) => {
                  const value = e.target.value.toUpperCase();
                  e.target.value = value;
                }}
              />
            </Form.Item>{" "}
            <Form.Item
              name={`dob`}
              label={"Date of birth"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter DOB!",
                },
              ]}
            >
              <DatePicker
                className="w-full"
                placeholder="Enter DOB"
                name="dob"
              />
            </Form.Item>
            <Form.Item
              name={`gender`}
              label={"Select Gender"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select gender!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a gender"
                filterOption={filterOption}
                className="w-full mt-2"
                options={[
                  {
                    label: "Male",
                    value: "M",
                  },
                  {
                    label: "Female",
                    value: "F",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={`shop_name`}
              label={"Shop Name"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter shop name!",
                },
              ]}
            >
              <CommonInput
                placeholder="Shop Name"
                // className="w-full mt-2"
                // options={deviceOptions}
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={btnLoading}
                className="w-full bg-primary border-none mt-4"
                style={{ color: "white" }}
                htmlType="submit"
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      {openOtpModal && (
        <YesValidateOtpModal
          isOpen={openOtpModal}
          setIsOpen={setOpenOtpModal}
        />
      )}
    </>
  );
};

export default AepsYesOnboard;
