import React, { useEffect, useState } from "react";
import { Images } from "../../Controller";
import {
  checkPasswordStatus,
  sendPasswordOtp,
  sendResetOtp,
  userLogin,
} from "../../ApiServices/Apifun";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Spin } from "antd";
import { ReactComponent as AepsloginIcon } from "../../Assets/logoicons/AEPS.svg";
import { ReactComponent as BbpsloginIcon } from "../../Assets/logoicons/BBPS.svg";
import { ReactComponent as KycloginIcon } from "../../Assets/logoicons/KYC.svg";
import { ReactComponent as PayoutloginIcon } from "../../Assets/logoicons/Payout.svg";
import { ReactComponent as RechargeloginIcon } from "../../Assets/logoicons/Recharge.svg";
import { ReactComponent as TravelloginIcon } from "../../Assets/logoicons/Travel.svg";
import { ReactComponent as WalletloginIcon } from "../../Assets/logoicons/Wallet.svg";

import {
  PortalName,
  dispatchtoast,
  getLocalStorageItem,
  mobilePattern,
  setLocalStorageItem,
} from "../../Utils";
import { IoMdCall } from "react-icons/io";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaUserTie } from "react-icons/fa";

const Login = () => {
  const navigate = useNavigate();
  const [field, setField] = useState({
    mobile: "",
    password: "",
    latitude: "",
    longitude: "",
  });
  const [passwordAvailable, setPasswordAvailable] = useState(null);
  const [BtnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasLocation, setHasLocation] = useState({
    has: 0,
    text: "",
  });
  const [form] = Form.useForm();

  const [isLocationEnabled, setLocationEnabled] = useState(false);
  const handleMobileNumberChange = (changedValues, allValues) => {
    if (changedValues.hasOwnProperty("mobile")) {
      setPasswordAvailable(null);
      const mobileNumber = changedValues.mobile || "";
      if (mobileNumber.length === 10) {
        setLoading(true);
        checkPasswordStatus({ mobile: allValues.mobile })
          .then((res) => {
            if (res.status) {
              setPasswordAvailable(true);
            } else if (res.message === "Mobile number not registered!") {
              setPasswordAvailable(false);
            } else {
              setPasswordAvailable(false);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
      }
    }
  };
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocalStorageItem("latitude", position.coords.latitude);
          setLocalStorageItem("longitude", position.coords.longitude);
          // setField({
          //   ...field,
          //   latitude: getLocalStorageItem("latitude"),
          //   longitude: getLocalStorageItem("longitude"),
          // });
          setField({
            ...field,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          if (error.code === 1) {
            setHasLocation({
              has: 1,
              text: "Location permission denied. Please allow location access.",
            });
          } else if (error.code === 2) {
            setHasLocation({ has: 2, text: "Location is not available." });
          } else {
            setHasLocation({
              has: 3,
              text: "An error occurred while getting your location.",
            });
          }
        }
      );
    }
  };
  useEffect(() => {
    if ("geolocation" in navigator) {
      setLocationEnabled(true);
    } else {
      setLocationEnabled(false);
      alert("Geolocation is not supported by your browser.");
    }
    getUserLocation();
    return () => {
      setPasswordAvailable(null);
    };
  }, []);

  const handlesubmit = async (val) => {
    if (!field.latitude || !field.longitude) {
      dispatchtoast("Enable location first", true);
    } else if (passwordAvailable) {
      setBtnLoading(true);
      const formData = new FormData();
      formData.append("latitude", getLocalStorageItem("latitude"));
      formData.append("longitude", getLocalStorageItem("longitude"));
      Object.entries(val).map(([key, value], index) => {
        formData.append(key, value);
      });
      userLogin(formData)
        .then((res) => {
          if (res.status) {
            navigate("/otpScreen", { state: val });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setBtnLoading(false));
    } else {
      setBtnLoading(true);
      sendPasswordOtp({ mobile: val.mobile })
        .then((res) => {
          if (res.status) {
            navigate("/setPassword", { state: { res, val } });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setBtnLoading(false));
    }
  };
  const handleSendResetOtp = () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("mobile", form.getFieldsValue()["mobile"]);
    sendResetOtp(formdata)
      .then((res) => {
        if (res.status) {
          navigate("/forgotPassword", { state: { otp_uid: res.otp_uid } });
          dispatchtoast("OTP send successfully!");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="flex justify-center bg-blue-100 h-screen items-center">
          <div className="grid grid-cols-1 md:grid-cols-2  w-full md:w-9/12 h-screen md:h-full  place-items-center">
            <div className="h-full md:h-5/6 shadow-lg shadow-gray-500   bg-primary rounded-tl-lg rounded-tr-lg md:rounded-tl-lg md:rounded-bl-lg  w-full px-10 flex flex-col justify-center items-center">
              <p className="mt-10">
                <FaUserTie color="white" size={60} />
              </p>
              <p className="text-white mt-10 font-[100] text-4xl">Welcome to</p>
              <p className="mt-6 text-3xl font-semibold text-white">
                {JSON.parse(localStorage?.getItem('detail'))?.company_name??"B2B"}
              </p>
              <p className="text-xs font-normal text-white mt-4">
                Bunch of all business solution logic
              </p>
              <img
                src={Images.loginillustration2}
                className="w-[300px] h-[200px] mt-4 m-4"
              />
            </div>
            <div className=" h-full md:h-5/6 shadow-lg shadow-gray-500 pt-8 md:pt-0 rounded-bl-lg rounded-br-lg md:rounded-br-xl md:rounded-tr-xl relative  w-full flex flex-col bg-white justify-center items-center">
              <img
                className="w-[100px]"
                src={getLocalStorageItem("detail")?.logo}
                style={{ filter: "drop-shadow(0px 1px 2px #cfc5c5)" }}
              />
              <p
                className="text-start mt-4 font-semibold text-xl"
                style={{ fontFamily: "system-ui" }}
              >
                Login to B2B
              </p>
              <div className="py-10 w-2/3">
                <Form
                  form={form}
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  onValuesChange={handleMobileNumberChange}
                  onFinishFailed={(err) => console.log(err)}
                  onFinish={(val) => handlesubmit(val)}
                >
                  <Form.Item
                    name={`mobile`}
                    label={"Mobile"}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter mobile number!",
                      },
                      {
                        pattern: mobilePattern,
                        message: "Please enter a valid mobile number",
                      },
                      {
                        whitespace: true,
                        message: "Field cannot contain only blank spaces",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter mobile number"
                      name="mobile"
                      maxLength={10}
                      onInput={(e) => {
                        const value = e.target.value.replace(/\D/g, "");
                        e.target.value = value;
                      }}
                      prefix={
                        <IoMdCall className=" h-6 w-6 p-1 rounded-full bg-bsgray400" />
                      }
                      className="outline-none text-xs border mt-1 rounded-none border-gray-500 hover:border-primary  h-9 px-2 "
                    />
                  </Form.Item>
                  {passwordAvailable && (
                    <div>
                      <Form.Item
                        name={`password`}
                        label={"Password"}
                        labelCol={{ span: 24 }}
                        rules={[
                          {
                            required: true,
                            message: "Enter Password!",
                          },
                          {
                            whitespace: true,
                            message: "Field cannot contain only blank spaces",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter Password"
                          name="password"
                          prefix={
                            <RiLockPasswordLine className=" h-6 w-6  p-1 rounded-full bg-bsgray400" />
                          }
                          className="outline-none text-xs border rounded-none mt-1 w-full border-gray-500 focus:border-primary  h-9 px-2 "
                        />
                      </Form.Item>
                      <p
                        onClick={handleSendResetOtp}
                        className="flex justify-end items-center text-black cursor-pointer"
                      >
                        Forgot Password?
                      </p>
                    </div>
                  )}
                  {passwordAvailable && (
                    <Form.Item>
                      <Button
                        // disabled={!field.latitude || !field.longitude}
                        htmlType="submit"
                        loading={BtnLoading}
                        className={`SignInBtn w-full  mt-4 $
                   bg-primary
               cursor-pointer
               flex justify-center border-none items-center h-12 rounded-full text-white text-[12px] font-bold`}
                      >
                        <p>Sign Me In</p>
                      </Button>
                    </Form.Item>
                  )}
                  {passwordAvailable === false && (
                    <Form.Item>
                      <Button
                        disabled={!field.latitude || !field.longitude}
                        htmlType="submit"
                        loading={BtnLoading}
                        className={`SignInBtn w-full  mt-4 $
                   bg-primary
               cursor-pointer
               flex justify-center border-none items-center h-12 rounded-full text-white text-[12px] font-bold`}
                      >
                        <p>Send OTP</p>
                      </Button>
                    </Form.Item>
                  )}
                </Form>
                <p className="text-xs my-4 text-red-500">{hasLocation.text}</p>
                <p onClick={()=>navigate("/register")} className="text-center cursor-pointer text-blue-400">Register Now</p>
              </div>
              <div className="absolute bottom-4  flex justify-center flex-wrap gap-4  w-full items-center  font-semibold text-3xl font-serif">
                <AepsloginIcon className="cursor-pointer" />
                <BbpsloginIcon className="cursor-pointer" />
                <KycloginIcon className="cursor-pointer" />
                <TravelloginIcon className="cursor-pointer" />
                <RechargeloginIcon className="cursor-pointer" />
                <PayoutloginIcon className="cursor-pointer" />
                <WalletloginIcon className="cursor-pointer" />
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default Login;
