import { Button, Form, Input, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  AddBankAccount,
  getBanks,
  getPayoutBanks,
  verifyBankAccount,
} from "../../../ApiServices/Apifun";
import {
  dispatchtoast,
  filterOption,
  getLocalStorageItem,
  ifscPattern,
} from "../../../Utils";
import { Images } from "../../../Controller";
import CommonInput from "../../../Common/CommonInput";

const BankDetails = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const[verifyBtnLoading,setVerifyBtnLoading]=useState(false)
  const [banks, setAllBanks] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [registeredBanks, setRegisteredBanks] = useState([]);
  const formref = useRef();
  const [form] = Form.useForm();
  const getAllRegistered = () => {
    setSpinner(true);
    getPayoutBanks()
      .then((res) => setRegisteredBanks(res.data.banks))
      .catch((err) => console.log(err))
      .finally(() => setSpinner(false));
  };
  useEffect(() => {
    getAllRegistered();
    getBanks()
      .then((res) => {
        setAllBanks(
          res.banks.map((i, index) => {
            return {
              label: i.bank_name,
              value: i.id,
              key: i?.bank_ifsc?i.bank_ifsc:"",
            };
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);
  const handleFinish = (val) => {
    let data={...val}
    data.bank_id=val.bank_id.value
    setBtnLoading(true);
    const formdata = new FormData();
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    Object.keys(data).forEach((key) => formdata.append(key, data[key]));
    AddBankAccount(formdata)
      .then((res) => {
        if (!res.status) {
          getAllRegistered();
        } else {
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleVerify=()=>{
    setVerifyBtnLoading(true)
    const formdata = new FormData();
    const data = form.getFieldsValue();
    let temp={...data}
    temp.bank_id=data.bank_id.value
    formdata.append("latitude", getLocalStorageItem("latitude"));
    formdata.append("longitude", getLocalStorageItem("longitude"));
    Object.keys(temp).map((key) => formdata.append(key, temp[key]));
    verifyBankAccount(formdata)
      .then((res) => {
        if (res.status) {
          dispatchtoast("Bank verified successfully!");
        } else {
          dispatchtoast(res.message, true);
          setVerifyBtnLoading(false);
        }
      })
      .catch((err) => {
        setVerifyBtnLoading(false);
      }).finally(()=>{
        setVerifyBtnLoading(false)
      })
  }
  const handlefillIFSC=(val)=>{
    formref.current.setFieldsValue({
      benificiary_ifsc:val.key
    })
  }
  return (
    <>
      {spinner ? (
        <div className="flex justify-center items-center h-[200px]">
          <Spin />
        </div>
      ) : (
        <div
          className={`grid ${
            registeredBanks.length === 0
              ? "grid-cols-1"
              : "grid-cols-1 md:grid-cols-2"
          } place-items-center gap-8`}
        >
          {registeredBanks.length !== 0 ? (
            registeredBanks.map((item, index) => {
              return (
                <div
                  key={index}
                  className="details overflow-y-scroll border-dashed border-2 h-full w-full  p-4 bg-loginbg border-primary   text-xs leading-6 mt-6"
                >
                  <div className="flex  justify-start  items-center">
                    <p className="w-1/2 md:w-1/3 font-medium ">Bank Name</p>
                    <p className="text-start">{item.bank_name}</p>
                  </div>
                  <div className="flex  justify-start  items-center">
                    <p className="w-1/2 md:w-1/3 font-medium ">
                      Account Number
                    </p>
                    <p className="">{item.account_no}</p>
                  </div>{" "}
                  <div className="flex  justify-start  items-center">
                    <p className="w-1/2 md:w-1/3 font-medium">IFSC Code</p>
                    <p className="text-start">{item.ifsc_code}</p>
                  </div>{" "}
                  <div className="flex  justify-start  items-center">
                    <p className="w-1/2 md:w-1/3 font-medium">
                      Account Holder Name
                    </p>
                    <p className="text-start">{item.account_holder}</p>
                  </div>{" "}
                </div>
              );
            })
          ) : (
            <>
              <img src={Images.nobank} className="w-14 h-14" alt="noBank" />
              <p className="flex justify-center items-center  font-semibold">
                Add Bank Account
              </p>
            </>
          )}
        </div>
      )}
      <div className="p-4  ">
        <Form
        ref={formref}
        form={form}
          name="basic"
          onFinishFailed={(err) => console.log(err)}
          onFinish={(val) => handleFinish(val)}
        >
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-x-5">
            <Form.Item
              name={`bank_id`}
              label={"Bank Name"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Select Bank Name!",
                },
              ]}
            >
              <Select
                options={banks}
                filterOption={filterOption}
                placeholder="Select Bank"
                showSearch
                labelInValue
                onChange={(val)=>handlefillIFSC(val)}
                name="bank_id"
                className=" text-sm  h-9 w-full  focus:border-primary    "
              />
            </Form.Item>{" "}
            <Form.Item
              name={`benificiary_account`}
              label={"Account Number"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter Account Number!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter Your Account Number!"
                name="benificiary_account"
              />
            </Form.Item>{" "}
            <Form.Item
              name={`benificiary_ifsc`}
              label={"IFSC Code"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter IFSC Code!",
                },
                {
                  pattern: ifscPattern,
                  message: "Please enter a valid IFSC number",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter IFSC Code"
                name="benificiary_ifsc"
                onInput={(e) => {
                  const value = e.target.value.toUpperCase();
                  e.target.value = value;
                }}
              />
            </Form.Item>{" "}
            <Form.Item
              name={`benificiary_name`}
              label={"Benificiary Name"}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter benificiary name!",
                },
                {
                  whitespace: true,
                  message: "Field cannot contain only blank spaces",
                },
              ]}
            >
              <CommonInput
                placeholder="Enter benificiary name"
                name="benificiary_name"
              />
            </Form.Item>{" "}
          </div>
          <div className="flex justify-start gap-4 items-center mt-2">
            <Form.Item
            >
              <Button
                loading={btnLoading}
                htmlType="submit"
                className="bg-primary outline-none border-none text-white"
              >
                Add
              </Button>
            </Form.Item>
            <Form.Item
            >
              <Button
                loading={verifyBtnLoading}
                onClick={handleVerify}
                htmlType="button"
                className="bg-primary outline-none border-none text-white"
              >
                Verify Bank
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default BankDetails;
